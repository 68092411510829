@import "./bny-colors.scss";
@import "../styles/vars.scss";

.page-container {
  z-index: 1;
  overflow: hidden;
  position: relative;
  background: $bny-teal-bg;
}

// Mobile View
.main-console-container {
  @extend .pt-md-2;
  @extend .pt-lg-4;
}

* > .main-console-title {
  // Relative position required for container of main-console-title
  @extend .position-relative;
}

.main-console-title:before {
  background-image: url("^./assets/img/bg-circuits-compressed.jpg");
  background-size: 200%;
  position: absolute;
  top: 0;
  content: '';
  display: block ;
  height: 100%;
  left: 0;
  right: 0;
  z-index: -1;
}

// Desktop View
@media(min-width: $screen-md) {

  .main-console-card {
    box-shadow: 2px 2px 5px $bny-teal;
  }
  .main-console-title {
    box-shadow: 2px 2px 5px $bny-dark-teal;
  }

  .warning-container {
    box-shadow: 2px 2px 5px $bny-teal;
  }

  .main-console-title:before {
    display: none;
  }

  .page-container {
    background-image: url("^./assets/img/bg-circuits-compressed.jpg");
    background-size: 100%;
    background-repeat: repeat-y;
  }

  .main-console-title {
    border-top: 2px solid;
    text-align: left !important;
  }

  // Apply tint to background
  .page-container:before {
    background: $bny-dark-teal;
    position: absolute;
    top: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -2;
    opacity: 0.6;
  }

  // Add background color slant
  .page-container:after {
    background: $bny-teal-bg;
    position: absolute;
    top: 20vh;
    content: '';
    display: block;
    height: 100vh;
    min-height: -webkit-fill-available;
    left: 0;
    right: -3px;
    z-index: -1;
    transform: skewY(-21deg);
    transform-origin: 100% 100%;
  }

}

.limited-width {
  @extend .w-100;
  @extend .px-0;
  @extend .container;

  // Limit width of elements. Allow full width up to large-sized screen, then limit to 60vw
  @media(max-width: $screen-md) {
    max-width: max($screen-lg, 60vw);
  }

}
