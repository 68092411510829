/** Material Component Styles

   Currently using material's indigo-pink theme (common default), which sets properties (colors, typography, etc)
   for material components. Hence, their styles differ from the rest of the application.

   Note: If you do not use a theme, certain component configs will not be set properly and will ultimately be more
   difficult to get those working as well. (e.g. dialog component will load poorly and need to be configured manually)

*/
@import "../bny/bny-colors.scss";

// Set loading bar main color
.mdc-linear-progress__bar-inner {
  border-color: $bny-teal-02 !important;
}

// Set loading bar background color
.mdc-linear-progress__buffer-bar {
  background-color: $bny-teal-bg !important;
}

// Set mat-radio button selection colors
.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
  border-color: $bny-teal-02 !important;
}

// Set mat-radio button inner-circle background color (otherwise white spot may occur in center for some zoom levels)
.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
  background: $bny-teal-02 !important;
}

// Resize mat-checkbox
.mdc-form-field .mdc-checkbox {
  padding: 0 !important;
  transform: scale(0.75)
}

// Center mat-checkbox. Needed due to custom resize
.mdc-checkbox .mdc-checkbox__background {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%,-50%);
}

// Resize mat-radio button
.mdc-radio {
  padding: 0.25rem !important;
  transform: scale(0.75)
}

// Resize native control elements
.mdc-checkbox .mdc-checkbox__native-control,
.mdc-radio .mdc-radio__native-control {
  top: 0px !important;
  left: 0px !important;
  height: 100% !important;
  width: 100% !important;
}

// Set checkbox colors
.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: $bny-teal-02 !important;
  background-color: $bny-teal-02 !important;
}

// Set accent colors (used by most mat inputs)
.mat-accent {
  --mdc-radio-selected-focus-icon-color: $bny-teal-02 !important;
  --mdc-radio-selected-hover-icon-color: $bny-teal-02 !important;
  --mdc-radio-selected-icon-color: $bny-teal-02 !important;
  --mdc-radio-selected-pressed-icon-color: $bny-teal-02 !important;
  --mat-mdc-radio-checked-ripple-color: $bny-teal-02 !important;
  --mat-radio-checked-ripple-color: $bny-teal-02 !important;
  --mdc-checkbox-selected-focus-icon-color: $bny-teal-02 !important;
  --mdc-checkbox-selected-hover-icon-color: $bny-teal-02 !important;
  --mdc-checkbox-selected-icon-color: $bny-teal-02 !important;
  --mdc-checkbox-selected-pressed-icon-color: $bny-teal-02 !important;
}

// Override material theme's default typography
.mdc-form-field {
  font-family: inherit !important;
  color: inherit !important;
  font-size: inherit !important;
}

// Hide checkbox ripple
.mdc-checkbox__ripple {
  opacity: 0 !important;
}

// Removed Chrome-specific UI quirk. After leaving focus for an input field, small lines may appear around inputs
.form-control:not(:focus), {
  box-shadow: 0 0 0 0.25rem transparent !important;
  --bs-box-shadow: 0 0 0 0.25rem transparent !important;
}
