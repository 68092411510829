/*
    Official BNY Color Palette.
    - Color usage is important for brand identity & consistency.
    - Brand Center Color Documentation: https://brandcenter.bnymellon.com/content/index/guid/color2?parent=83

    Palette Colors
    - Primary: Dark Teal
    - Secondary: Dark Purple, Slate, Dark Green, White
    - Accents: Orange, Yellow, Pink

    Color Usage
    - Tints and background colors are derived form anchor colors
    - Only use approved color combinations, in order to meet ADA compliance

    Color Misuse
    - Do not use unapproved color combinations
    - Do not use accent colors as background colors
    - Do not add new colors or tints
    - Do not combine multiple anchor colors in a single composition
    - Do not use type color combinations without adequate contrast
    - Do not change proportions of anchor and accent colors. Anchors should be prominent
*/
// Primary Color (Teal)
$bny-dark-teal:     #00243d; // Anchor
$bny-teal:          #00485e;
$bny-teal-02:       #05687f;
$bny-teal-03:       #2d9bad;
$bny-teal-04:       #6abdc6;
$bny-teal-05:       #ace2e5;
$bny-teal-06:       #cff4f4;

// Secondary Color (Slate)
$bny-slate:         #353535; // Anchor
$bny-slate-black:   #000000;
$bny-slate-02:      #58595b;
$bny-slate-03:      #808184;
$bny-slate-04:      #c4c5c6;
$bny-slate-05:      #dadadc;
$bny-slate-06:      #eaeaea;

// Secondary Color (White)
$bny-white:         #ffffff; // Anchor

// Accent Color (Orange)
$bny-orange:        #ff6120; // Anchor

// Background Colors
$bny-orange-bg:     #f5f0eb;
$bny-teal-bg:       #e3ebed;
$bny-pink-bg:       #eee3e6;
$bny-yellow-bg:     #ece8db;
$bny-green-bg:      #e2ede8;
