@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/bny/bny-colors.scss";
@import "./assets/bny/bny-background.scss";
@import "./assets/styles/mat-customization.scss";
@import "./assets/styles/vars.scss";

html {
  min-font-size: 10px;
}

.pointerOnHover {
  cursor: pointer;
}

body {
  background-color: $bny-teal-bg;
}

.header-format {
  background: $bny-dark-teal;
}

.ah-footer {
  background: $bny-dark-teal;
  color: $bny-white;
}

/* Set title to top */
.main-console-title {
  padding: 0.5rem 10px;
  height: 100%;
  font-size: 1.5rem;
  color: $bny-white;
  text-align: center;
  border-top: 4px solid;
  border-color: $bny-teal-03;
  background-color: rgba($bny-dark-teal, 0.8) !important;
}

.reference {
  text-decoration: underline;
  color: inherit;
}

.reference:hover {
  color: $bny-slate;
  cursor: pointer;
}

.btn-spacer {
  flex: 1 1 auto;
}

.main-console-card {
  position: relative;
  color: $bny-teal;
  padding: 2rem;
  background: $bny-white;
  overflow-wrap: break-word;
  text-align: start;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (min-width: $screen-md) {

  // Grow font size with screen size, with a max of 36px
  html {
    font-size: min(36px, max(1vh, 0.5vw, 12px));
  }
}

.dialog-header {
  border-bottom: 1px solid $bny-slate-05;
  padding-bottom: 4px;
}

mat-dialog-content,
mat-dialog-actions {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.row-marg-bottom {
  margin-bottom: 15px;
}

.row-marg-top {
  margin-top: 15px;
}

.warning-container {
  background: $bny-orange-bg;
  color: $bny-slate-black;
  border: 1px solid $bny-orange;
}

.tos-notice-container {
  border: 1px solid $bny-teal;
  background-color: $bny-teal-bg;
  border-radius: 3px;
}

.alert-red {
  color: red;
}

.main-console-title p {
  margin-top: 10px;
  font-size: 1rem;
}

.user-input{
  font-size: 1rem;
}

.resend-button {
  height: 100%;
  font-size: 1rem;
}

.toolbar-btn {
  text-decoration: none;
  border-radius: 9px;
  background-color: $bny-teal-04;
  border: solid $bny-teal-04;
  transition: background-color .10s ease-in-out;
  color: $bny-slate-06;
  width:100%;
  height:100%;
  margin-bottom:10px;
}

.toolbar-btn:hover {
  background-color: $bny-teal-03;
  border-color: $bny-teal-03;
  box-shadow: 0 1px 3px 0 $bny-teal-04;
}

.table-btn {
  background-color: $bny-teal-02;
  color: $bny-slate-06;
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
}

.table-btn:hover {
  background-color: $bny-dark-teal;
}

.primary-btn {
  text-decoration: none;
  text-align: center;
  border-radius: 3px;
  background-color: $bny-teal-02;
  border: 1px solid $bny-teal;
  transition: background-color .10s ease-in-out;
  color: $bny-slate-06;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-btn:hover {
  background-color: $bny-teal;
}

.secondary-btn {
  text-decoration: none;
  border-radius: 3px;
  background-color: $bny-white;
  border: 1px solid $bny-slate-03;
  transition: background-color .10s ease-in-out;
  color: $bny-teal;
  width:100%;
}

.secondary-btn:hover {
  background-color: $bny-slate-06;
}

.btn-width-75{
  width: 75%;
}

.btn-width-100{
  width: 100%;
}

.col:has(.primary-btn, .secondary-btn)  {
  margin-bottom: 10px;
}

.col > .primary-btn,
.col > .secondary-btn {
  height:100%;
}


.white-bnym-logo {
  height: 0.75rem;
  padding-right: 5px;
  vertical-align: middle;
}

.primary-btn:disabled {
  background-color: $bny-slate-03;
  border-color: $bny-slate-03;
}

.primary-btn:disabled:hover {
  background-color: $bny-slate-03;
  border-color: $bny-slate-03;
  cursor: not-allowed;
}

.secondary-btn:disabled {
  background-color: $bny-slate-05;
  border-color: $bny-slate-05;
  color: $bny-slate-black;
}

.secondary-btn:disabled:hover {
  background-color: $bny-slate-03;
  border-color: $bny-slate-03;
  cursor: not-allowed;
}

.align-right {
  text-align: right;
}

.self-service-content {
  font-size: .9rem;
}

.checkbox-label {
  font-size: 0.9rem;
}

.tooltip-icon {
  padding-left: 3px;
  height: 0.9rem;
  margin: auto;
  vertical-align: middle;
}

.contacts-list {
  list-style-type: none;
}

.key {
  text-align: center;
  display: block;
}

.btn-centered {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
}

.securid-label{
  height: 60px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
}

.logout-btn {
  font-size: 1.25rem;
  color: $bny-white;
}

.logout-btn:hover {
  color: $bny-teal-03;
}

.cred-status {
  border: 2px solid $bny-teal-03;
  background-color: $bny-teal-bg;
  border-radius: 3px;
  font-size: 1.1rem;
}

.cred-row-btn {
  @extend .primary-btn;
  @extend .align-bottom;
  height: 100%;
}

.factors-list:nth-child(odd) {
  background-color: rgba($bny-teal-bg, 0.5) !important;
}

.factors-list {
  border-top: 1px solid $bny-teal-bg;
  border-bottom: 1px solid $bny-teal-bg;
}

.factorSelection {
  border-top: 1px solid $bny-teal-bg;
  border-bottom: 1px solid $bny-teal-bg;
}

/* Mimic the blue glow for bootstrap input fields when in focus */
input:focus-within,
input:focus {
  background: white;
  border-color: tint-color($bny-teal, 50%);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba($bny-teal-03, 0.5) !important;
}


// Removed Chrome-specific UI quirk. After leaving focus for an input field, small lines may appear around inputs
input:not(:focus-within),
input:not(:focus){
  box-shadow: 0 0 0 0.25rem transparent !important;
  --bs-box-shadow: 0 0 0 0.25rem transparent !important;
}
